import {
  createRouter,
  createWebHistory,
  createWebHashHistory
} from 'vue-router'

const routes = [

  {
    path: '/preload',
    alias: '/loading',
    name: 'preload',
    component: () => import( /* webpackChunkName: "preload" */ '../views/preload.vue')
  },
  {
    path: '/',
    name: 'swiper',
    component: () => import( /* webpackChunkName: "swiper" */ '../views/swiper.vue')
  },
  // {
  //   path: '/:pathMatch(.*)*',
  //   name: 'swiper',
  //   component: () => import( /* webpackChunkName: "swiper" */ '../views/swiper.vue')
  // },

]

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {

  // console.log("router.beforeEach from:", from.fullPath, " to:", to.fullPath);
  if (!from.matched.length && to.path!='/loading') {
    // console.log(to.fullPath.split('?'));
    // console.log(from.matched.length);
    let path = '/loading' //+'?'+ to.fullPath.split('?')[1]
    let query = to.query??''
    let hash = to.hash??''
    // console.log("router.beforeEach to:", path, query, hash );
    // 路由配置
    next({
      path,
      hash,
      query,
      // replace: true,
    })
  }else {
    next()
  }

  // next()
})
router.afterEach(() => {
  let loading = document.querySelector('#loading')
  if (loading.style.display !== 'none') {
    loading.style.display = 'none'
  }
})

export default router