import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// import 'swiper/swiper-bundle.css'
import '../src/styles/main.scss'

const app = createApp(App)
app.use(router)

import { Overlay, Image as VanImage, Loading, Toast, List, Swipe, SwipeItem   } from 'vant';
app.use(Overlay)
app.use(VanImage);
app.use(Loading);
app.use(Toast);
app.use(List);
app.use(Swipe);
app.use(SwipeItem);

app.mount('#app')
