/***
 * 获取微信Code
 * @param 
 * @returns 重定向
 */
export function getCode() {

    let env = process.env.NODE_ENV =='development'?'local':''
    console.log(process.env.NODE_ENV);
    // window.location.href = `/api/wechat/getCode/start${env}`;
}

//类似 #/top/032230087 的解析，返回['top','032230087']
export function parseHashString(){
    let _hash = window.location.hash;
    // console.log("parseHashString, hash:",_hash);
    if(!!_hash){
        let _arr = _hash.split('/');
        if(_arr.length > 2){
            return _arr.slice(1);
        }
    }
    return [];
}

/**
 * 获取地址栏参数
 * @param {String} key 获取参数的Key
 * @returns 参数值
 */
export function GetQueryString(key) {
    let _url = window.location.href;
    let _arr = _url.split('?');
    if (_arr.length <= 2) {
        let tmp = _url.split('?')[1]; // ?后为参数数列字符串 形如'a=1&b=2'
        if (!tmp) {
            // console.log('no param');
            return false
        }
        let arr = tmp.split('&'); // ['a=1','b=2']
        let obj = {};
        for (let i = 0; i < arr.length; i++) {
            let _key = arr[i].split('=')[0];
            let _value = arr[i].split('=')[1];
            obj[_key] = _value
        }

        return obj[key]
    } else {
        // 不止一个?号
        let value = '';
        for (let i = 0; i < _arr.length; i++) {
            if (_arr[i].indexOf(key + '=') > -1) {
                //console.log(key + '=' + _arr[i].split('=')[1]);
                value = _arr[i].split('=')[1];
            }
        }
        return value
    }
}