import axios from 'axios'

function ShareUpdate(sharetitle,sharedesc,sharelink,shareimgUrl,callback){
    console.log("ShareUpdate...");
    wx.updateAppMessageShareData({
        title: sharetitle, // 分享标题
        desc: sharedesc, // 分享描述
        link: sharelink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareimgUrl, // 分享图标
        success: function () {
            // 设置成功
            // 用户确认分享后执行的回调函数
            if (typeof callback === "function") {
                //是函数
                callback();
            }
        },
        cancel: function () {
            // 用户取消分享后执行的回调函数
        },
        fail: function () {
            //alert("share2 fail");
        },
    });
    wx.updateTimelineShareData({
        title: sharetitle, // 分享标题
        link: sharelink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareimgUrl, // 分享图标
        success: function () {
            // 设置成功
            if (typeof callback === "function") {
                //是函数
                callback();
            }
        },
        cancel: function () {
            // 用户取消分享后执行的回调函数
        },
        fail: function () {
            //alert("share1 fail");
        },
    });

    //获取“分享到朋友圈”按钮点击状态及自定义分享内容接口
    wx.onMenuShareTimeline({
        title: sharetitle, // 分享标题
        link: sharelink, // 分享链接
        imgUrl: shareimgUrl, // 分享图标
        success: function () {
            // 用户确认分享后执行的回调函数
            if (typeof callback === "function") {
                //是函数
                callback();
            }
        },
        cancel: function () {
            // 用户取消分享后执行的回调函数
        },
        fail: function () {
            //alert("share1 fail");
        },
    });
    //获取“分享给朋友”按钮点击状态及自定义分享内容接口
    wx.onMenuShareAppMessage({
        title: sharetitle, // 分享标题
        desc: sharedesc, // 分享描述
        link: sharelink, // 分享链接
        imgUrl: shareimgUrl, // 分享图标
        type: "link", // 分享类型,music、video或link，不填默认为link
        dataUrl: "", // 如果type是music或video，则要提供数据链接，默认为空
        success: function () {
            // 用户确认分享后执行的回调函数
            if (typeof callback === "function") {
                //是函数
                callback();
            }
            // console.log("share2 success");
        },
        cancel: function () {
            // 用户取消分享后执行的回调函数
        },
        fail: function () {
            //alert("share2 fail");
        },
    });
    //获取“分享到QQ”按钮点击状态及自定义分享内容接口
    wx.onMenuShareQQ({
        title: sharetitle, // 分享标题
        desc: sharedesc, // 分享描述
        link: sharelink, // 分享链接
        imgUrl: shareimgUrl, // 分享图标
        success: function () {
            // 用户确认分享后执行的回调函数
            if (typeof callback === "function") {
                //是函数
                callback();
            }
        },
        cancel: function () {
            // 用户取消分享后执行的回调函数
        },
    });
    //获取“分享到腾讯微博”按钮点击状态及自定义分享内容接口
    wx.onMenuShareWeibo({
        title: sharetitle, // 分享标题
        desc: sharedesc, // 分享描述
        link: sharelink, // 分享链接
        imgUrl: shareimgUrl, // 分享图标
        success: function () {
            // 用户确认分享后执行的回调函数
            if (typeof callback === "function") {
                //是函数
                callback();
            }
        },
        cancel: function () {
            // 用户取消分享后执行的回调函数
        },
    });
    //获取“分享到QQ空间”按钮点击状态及自定义分享内容接口
    wx.onMenuShareQZone({
        title: sharetitle, // 分享标题
        desc: sharedesc, // 分享描述
        link: sharelink, // 分享链接
        imgUrl: shareimgUrl, // 分享图标
        success: function () {
            // 用户确认分享后执行的回调函数
            if (typeof callback === "function") {
                //是函数
                callback();
            }
        },
        cancel: function () {
            // 用户取消分享后执行的回调函数
        },
    });
}
export default {
    //微信分享方法
    ShareLocal: function (title, desc, imgurl, link, callback) {
        var url = window.location.href;
        if (url.indexOf("#") > -1) {
            url = url.split("#")[0];
        }
        var sharetitle = title || document.title;
        var sharelink = link || window.location.href;
        var sharedesc = desc || document.title;
        var shareimgUrl = imgurl || "https://card.lyd.com.cn/img/lyw_news_img3.png?v=5";
        //todo 修改分享图片、网址
        if(!window.wx){
            callback && callback();
            return;
        } 
        if( window.wx_cfg ){
            ShareUpdate(sharetitle,sharedesc,sharelink,shareimgUrl,callback);
            return;
        }
        axios({
            url: "//shouji.lyd.com.cn/sam/video/ajax_share_wx/?url=" + encodeURIComponent(url),
            method: "get",
            // withCredentials: true,
        }).then(res => {
            window.wx_cfg = res.data

            var wx = window.wx
            var wx_cfg = window.wx_cfg
            console.log("ShareLocal wx_cfg:",wx_cfg);
            wx.config({
                debug: false, //值为true时进入debug模式，可以打出状态值
                appId: wx_cfg.appId,
                timestamp: wx_cfg.timestamp,
                nonceStr: wx_cfg.nonceStr,
                signature: wx_cfg.signature,
                jsApiList: [
                    "onMenuShareTimeline",
                    "onMenuShareAppMessage",
                    "updateAppMessageShareData",
                    "updateTimelineShareData",
                    // 所有要调用的 API 都要加到这个列表中
                ],
            });
            wx.ready(function () {
                wx.checkJsApi({
                    jsApiList: [
                        "onMenuShareTimeline",
                        "onMenuShareAppMessage",
                        "updateAppMessageShareData",
                        "updateTimelineShareData",
                    ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
                    success: function () {
                        // 以键值对的形式返回，可用的api值true，不可用为false              
                    },
                });

                wx.error(function () {});

                // 自定义分享内容
                wx.ready(function () { //需在用户可能点击分享按钮前就先调用
                    ShareUpdate(sharetitle,sharedesc,sharelink,shareimgUrl,callback);
                });
                
            });
        })


    }
}