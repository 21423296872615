import axios from 'axios';
import {
    ref,
    reactive,
    inject,
    watch,
    computed,
} from 'vue'
import useGlobal from './useGlobal';

import {
    Howl,
    Howler
} from 'howler'
const flvJs = require('flv.js')

export default function usePlayer() {

    // 播放器播放状态 0未播放 1暂停 2播放中
    const playerStatus = ref(0)

    // 行车模式
    const playerDriving = ref(false)

    let playerTimer = ref(0)

    // 播放器数据源
    let playerSourceData = reactive({
        type: 0,
        video: '',
        abstract: '',
        content: '',
    })

    // 播放中的videoDom
    let playerVideoDom = document.querySelectorAll('.swiper-slide-active .card-item--active0 video')[0] || null

    const videoStop = () => {
        playerInstance && playerInstance.pause()
        playerInstance && playerInstance.unload()
        playerInstance && playerInstance.detachMediaElement()
        playerInstance = null
    }

    const playerStop = () => {
        // if (!!playerVideoDom) playerVideoDom.pause()
        if (!!playerVideoDom) videoStop()

        if (howlerList.length) {
            curParagraphIndex.value = 0
            howlerList.length = 0
            Howler.stop()
        }
    }

    const playerPause = () => {
        // if (!!playerVideoDom) playerVideoDom.pause()
        if (!!playerVideoDom) videoStop()
        if (howlerList.length) {
            howlerList[curParagraphIndex.value].pause()
        }
    }
    const playerPlay = async () => {
        // console.log('playerSourceData: ', playerSourceData);

        if (playerSourceData['video']) {
            console.log("playerPlay video, playerStatus:", playerStatus.value)
            // 如果是当前稿件是视频
            playerVideoDom = document.querySelectorAll('.swiper-slide-active .card-item--active0 video')[0]
            // playerVideoDom.play()
            initVideo()
            
            // playerStatus.value = 2
        } else if (!!playerSourceData.title||!!playerSourceData.abstract) {
            console.log("playerPlay audio, load-length:",howlerList.length)
            if (!howlerList.length) {
                recycleFetchAudio()
            } else {
                howlerList[curParagraphIndex.value].play()
            }
        }
    }

    const triggerPlayerStatus = (oldStatus) => {

        if (playerSourceData)

            switch (oldStatus) {
                case 0:
                    //播放
                    playerStatus.value = 2
                    break;
                case 1:
                    //暂停
                    playerStatus.value = 1
                    break;
                case 2:
                    //停止
                    playerStatus.value = 0
                    break;
                default:
                    break;
            }
    }


    watch([playerStatus], ([newPlayerStatus], [oldPlayerStatus]) => {
        console.log('newPlayerStatus: ', newPlayerStatus);
        console.log('oldPlayerStatus: ', oldPlayerStatus);
        if (newPlayerStatus === 0) {
            // 从1到0 即暂停时翻页
            console.log('停止播放');
            playerStop()
            // 关闭自动播放
            playerDriving.value = false;
            //
            clearTimeout(playerTimer);
            playerTimer = setTimeout(()=>{
                playerStatus.value==0 && fetchAndPlay("endCarSpeech")
            },600)
        } else if (newPlayerStatus === 2) {
            //
            fetchAndPlay("startCarSpeech")
            // 从0到2 即开始播放
            console.log('播放');
            clearTimeout(playerTimer);
            playerTimer = setTimeout(()=>{
                playerStatus.value==2 && playerPlay()
            },900)
        } else if (newPlayerStatus === 1) {
            // 从2到1 即暂停
            console.log('暂停');
            playerPause()
        }
    })



    let readerTextArr = reactive([]) // 当前文章段落列表
    let readerCurParagraph = ref('') // 当前文章第一段
    // 处理文章数据
    const handleReaderData = (htmlStr) => {
        let oDiv = document.createElement("div");
        oDiv.innerHTML = htmlStr.replace(/poster=\"|src=\"/g, 'data-src="');
        let textArr = [];
        // console.log("handleReaderData oDiv.childNodes",oDiv.childNodes)
        for (let i in oDiv.childNodes) {
            if (!!oDiv.childNodes[i].innerText) {
                textArr.push(oDiv.childNodes[i].innerText);
            }
        }
        textArr = [...new Set(textArr)]
        readerTextArr = reactive([...textArr])
        readerCurParagraph.value = textArr[0]
        readerCurAudioSrc.value = formTTS(readerCurParagraph.value)
        readerAudioSrcArr = reactive([])
        readerTextArr.forEach(text => {
            readerAudioSrcArr.push(formTTS(text))
        })

        // console.log('readerAudioSrcArr ==== ', readerAudioSrcArr);
        // readerAudioSrcArr = reactive([readerCurAudioSrc.value])


    }

    const howlerCanplay = ref(false)
    const initHowl = (src) => {
        return new Howl({
            format: ['mp3'],
            src,
            onload: () => {
                howlerCanplay.value = true
            },
            onend: () => {
                curParagraphIndex.value++
                if (curParagraphIndex.value < howlerList.length) {
                    howlerList[curParagraphIndex.value].play()
                }else{
                    console.log("play audio end.", playerStatus.value)
                    if( playerStatus.value === 2 ){
                        playerDriving.value = true
                    }else{
                        triggerPlayerStatus(2)
                    }
                }
            },
            onstop: () => {
                // console.log("play audio stop.")
            },
        })
    }

    const initVideo = () => {
        let playerVideoDom = document.querySelectorAll('.swiper-slide-active .card-item--active0 video')[0]
        // playerVideoDom.play()
        playerInstance = flvJs.createPlayer({
            type: 'mp4',
            hasAudio: false,
            isLive: true,
            url: playerSourceData.video
        }, {
            enableWorker: false,
            enableStashBuffer: false
        })
        playerInstance.attachMediaElement(playerVideoDom)
        playerInstance.load()
        playerInstance.play()

        

        
        if(winRatio.value > 1 && winRatio != Infinity) {
            // videoFullScreen(playerVideoDom)
        }
        playerVideoDom.onended = () => {
            console.log("play video end.", playerStatus.value)
            if( playerStatus.value === 2 ){
                playerDriving.value = true
            }else{
                triggerPlayerStatus(2)
                exitFullscreen()
            }
        }
    }


    let playerInstance;

    // 初始化播放器
    const playerInit = (data) => {
        // console.log("usePlayer playerInit, data:", data)
        // 播放器状态重置
        let curPlayerStatus = playerStatus.value
        if( curPlayerStatus===2 ){
            console.log('playerInit 停止上一个播放...');
            playerStop();
        }
        // playerStatus.value = 0
        // 接收数据源
        playerSourceData = data
        if (!!playerSourceData && !playerSourceData.video) {
            console.log('playerInit 文章:', playerSourceData.nid, playerSourceData.time_str, playerSourceData.title, playerSourceData.abstract);
            // 普通文章稿件 处理文章内容以朗读
            let readerAudioText = "<p>"+playerSourceData.time_str+"</p>"+"<p>"+playerSourceData.title+"</p>"+"<p>"+(playerSourceData.abstract||"")+"</p>"+"<div></div>"
            handleReaderData(readerAudioText)
        }
        if(!!playerSourceData && playerSourceData.video) {
            console.log('playerInit 是视频:',playerSourceData.nid, playerVideoDom);
            // playerInstance = flvJs.createPlayer({
            //     type: 'mp4',
            //     hasAudio: false,
            //     isLive: true,
            //     url: playerSourceData.videoUrl
            // }, {
            //     enableWorker: false,
            //     enableStashBuffer: false
            // })
            // playerInstance.attachMediaElement(playerVideoDom)
            // playerInstance.load()
            // playerInstance.play()

        }

        //连续播放设置
        if( curPlayerStatus==2 ){
            console.log('playerInit 1s后播放下一个...');
            clearTimeout(playerTimer);
            playerTimer = setTimeout(()=>{
                playerStatus.value==2 && playerPlay();
            },1000);
        }
    }

    let curParagraphIndex = ref(0)
    let readerAudioSrcArr = reactive([]) // 当前文章音频地址列表
    let readerCurAudioSrc = ref('')
    // 生成播放音频地址
    const formTTS = (e) => {
        let params = {
                tex: encodeURI(e),
                cuid: "lyd",
                cod: 2,
                lan: "zh",
                ctp: 1,
                pdt: 301,
                spd: 5,
                per: 0,
                vol: 5,
                pit: 5,


            },
            paramsArr = []

        for (let key in params) paramsArr.push(key + '=' + params[key])
        return "https://tts.baidu.com/text2audio?" + paramsArr.join("&")
    }

    // 通过音频地址获取到blob地址用于howler播放
    const fetchAudio = async (src) => {
        // console.log("fetchAudio src:",src)
        let res = await axios.get(src, {
            responseType: 'blob',
        })
        // console.log("fetchAudio res:",res)
        let blob = res.data
        let url = window.URL.createObjectURL(blob)
        // console.log("fetchAudio url:",url)
        return url
    }

    let howlerList = reactive([])
    const recycleFetchAudio = async () => {
        for (let i = 0; i < readerAudioSrcArr.length; i++) {
            let blob = await fetchAudio(readerAudioSrcArr[i])
            let sound = initHowl(blob)
            if (i === 0) {
                sound.play()
            }
            if (i > 0 && howlerList.length === 0) {
                return
            } else {
                howlerList.push(sound)
            }
        }
    }

    const initAudio = (src, volume=1.0) => {
        // console.log("initAudio... src:",src,"volume:",volume)
        return new Howl({
            format: ['mp3'],
            volume: volume,
            src: src,
            onload: () => {
                // console.log("initAudio load.",src)
            },
            onend: () => {
                // console.log("initAudio end.",src)
            },
            onstop: () => {
                // console.log("initAudio stop.",src)
            },
        })
    }

    const localAudioList = reactive({
        "startCarSpeech":{
            url:require("../assets/start-car-speech.mp3"),
            src:"",
            sound:null,
        },
        "endCarSpeech":{
            url:require("../assets/end-car-speech.mp3"),
            src:"",
            sound:null,
        },
        "swiperEffect":{
            url:require("../assets/left-right.mp3"),
            src:"",
            sound:null,
        },
        "cardEffect":{
            url:require("../assets/effect.mp3"),
            src:"",
            sound:null,
        },
    })

    const fetchAndPlay = async (name) => {
        if( localAudioList[name]["src"]=="" ){
            let url = localAudioList[name]["url"]
            console.log("fetchAndPlay,load name:",name)
            localAudioList[name]["src"] = await fetchAudio(url)
        }
        if( localAudioList[name]["sound"]==null ){
            let src = localAudioList[name]["src"]
            let volume = name=="swiperEffect" ? 3.0 : 1.0;
            let sound = initAudio(src,volume)
            console.log("fetchAndPlay,init name:",name,"sound:",sound.state())
            localAudioList[name]["sound"] = sound
            setTimeout(()=>{
                sound && sound.play()
            },100);
            return;
        }
        let sound = localAudioList[name]["sound"]
        console.log("fetchAndPlay,play name:",name)
        sound && sound.play()
    }

    const videoFullScreen = (video) => {
        if(!video){
            return;
        }
        if (video.requestFullscreen) {
            video.requestFullscreen()
        } else if (video.mozRequestFullScreen) {
            video.mozRequestFullScreen();
        } else if (video.webkitRequestFullScreen) {
            video.webkitRequestFullScreen();
        } else {
            video.webkitEnterFullscreen();
        }
    }
    const exitFullscreen = () => {
        var de = document;
        if (de.exitFullscreen) {
            de.exitFullscreen();
        } else if (de.mozCancelFullScreen) {
            de.mozCancelFullScreen();
        } else if (de.webkitCancelFullScreen) {
            de.webkitCancelFullScreen();
        }
    }
    const checkFullScreen = () => {
        var isFull = document.webkitIsFullScreen || document.mozFullScreen ||
            document.msFullscreenElement || document.fullscreenElement
        if (isFull == null || isFull == undefined) {
            isFull = false
        }
        return isFull
    }

    const winWidth = ref(document.body.offsetWidth)
    const winHeight = ref(document.body.offsetHeight)
    const winRatio = computed({
        get() {
            return winWidth.value / winHeight.value
        },
        set(val) {
            console.log(val);
        }
    })

    watch(winRatio, (nv, ov) => {
        console.log(nv, ov);
        console.log(playerStatus.value, checkFullScreen());
        if(nv > ov && nv > 1 && !checkFullScreen()) {
            console.log('横屏了且播放中');
            let playerVideoDom = document.querySelectorAll('.swiper-slide-active .card-item--active0 video')[0] || null
            // console.log(playerStatus.value);
            videoFullScreen(playerVideoDom)
            // playerVideoDom.play()

        }else {
            // exitFullscreen()
        }
    })


    return {
        playerStatus,
        playerDriving,
        playerInit,
        playerPause,
        playerPlay,
        triggerPlayerStatus,
        fetchAndPlay,
        winWidth,
        winHeight,
        winRatio
    }
}