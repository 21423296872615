import request from '../utils/request'

// export function fetchData(page) {
//     return request({
//         url: `list_category_444_page_${page}.json`,
//         method: 'get'

//     })
// }

export function fetchMenuData() {
    return request({
        url: `/menuApi/`,
        method: 'get'
    })
}

export function fetchNewsData(category, page, newsId) {
    // console.log("data fetchNewsData:", category, page, newsId)
    return request({
        url: `/newsApi/`,
        method: 'get',
        params: {cateId:category,newsId:newsId,page:page,size:10}
    })
}

export function fetchData1(category, page) {
    return request({
        url: `${category}_page_${page}.json`,
        method: 'get'
    })
}

export function fetchDetail(articleId) {
    return request({
        url: `${articleId}.json`,
        method: 'get'
    })
}