import {
    ref,
    reactive,
    computed,
    watch
} from 'vue'
import {
    fetchMenuData,
    fetchNewsData,
    fetchData1,
    fetchDetail,
} from '../api/data'

import wxshare from "../utils/wxshare";
import usePlayer from "../composables/usePlayer";
const {
    playerStatus,
    playerDriving,
    playerInit,
    playerPause,
    playerPlay,
    triggerPlayerStatus,
    fetchAndPlay,
    winWidth,
    winHeight,
    winRatio
} = usePlayer();

import {
    parseHashString,
    GetQueryString
} from '../utils';

import {
    useRoute,
    useRouter
} from 'vue-router'
import Cookies from 'js-cookie'

export default function useGlobal() {

    const $route = useRoute()
    const $router = useRouter()

    const pageLimit = ref(10)
    const pageSize = ref(10)

    let firstFlycard = reactive({})

    // 栏目列表
    let categoryList = reactive([{
            id: '1010',
            path: 'top',
            text: '推荐',
            page: 1,
            articleList: [],
            articleIndex: 0,
        },
        {
            id: '1015',
            path: 'luoyang',
            text: '洛阳',
            page: 1,
            articleList: [],
            articleIndex: 0,
        },
        {
            id: '1025',
            path: 'xianqu',
            text: '县区',
            page: 1,
            articleList: [],
            articleIndex: 0,
        },
        {
            id: '1065',
            path: 'jingdian',
            text: '经典',
            page: 1,
            articleList: [],
            articleIndex: 0,
        },
        {
            id: '1070',
            path: 'tupian',
            text: '图片',
            page: 1,
            articleList: [],
            articleIndex: 0,
        },
        // {
        //     id: '1080',
        //     path: 'shipin',
        //     text: '视频',
        //     page: 1,
        //     articleList: [],
        //     articleIndex: 0,
        // },
        // {
        //     id: '1045',
        //     path: 'fangchan',
        //     text: '房产',
        //     page: 1,
        //     articleList: [],
        //     articleIndex: 0,
        // },
        // {
        //     id: '1055',
        //     path: 'jiaoyu',
        //     text: '教育',
        //     page: 1,
        //     articleList: [],
        //     articleIndex: 0,
        // },
        // {
        //     id: '1035',
        //     path: 'jiankang',
        //     text: '健康',
        //     page: 1,
        //     articleList: [],
        //     articleIndex: 0,
        // },
        // {
        //     id: '1085',
        //     path: 'bxhs',
        //     text: '呼声',
        //     page: 1,
        //     articleList: [],
        //     articleIndex: 0,
        // },
    ])


    // 当前栏目下标
    const curCategoryIndex = ref(0)
    // 当前栏目
    const curCategory = computed(() => {
        return categoryList[curCategoryIndex.value]
    })
    // 当前栏目路径
    const curCategoryPath = computed(() => {
        return categoryList[curCategoryIndex.value]['path']
    })
    // 当前栏目Id
    const curCategoryId = computed({
        get: () => {
            return categoryList[curCategoryIndex.value]['id']
        },
        set: (val) => {
            categoryList[curCategoryIndex.value]['id'] = val
        }
    })
    // 当前栏目页码
    const curPage = computed({
        get: () => {
            return categoryList[curCategoryIndex.value]['page']
        },
        set: (val) => {
            categoryList[curCategoryIndex.value]['page'] = val
        }
    })


    // 栏目当前文章列表
    const curArticleList = computed(() => {
        return categoryList[curCategoryIndex.value]['articleList']
    })
    // 栏目当前文章下标
    const curArticleIndex = computed({
        get: () => {
            return categoryList[curCategoryIndex.value]['articleIndex']
        },
        set: (val) => {
            categoryList[curCategoryIndex.value]['articleIndex'] = val
        }
    })
    // 栏目当前文章
    const curArticle = computed(() => {
        let index = curArticleIndex.value
        let list = curArticleList.value
        return list[index]
    })

    // 全局飞卡数据
    const globalData = reactive([])
    globalData.fill([], categoryList.length)

    // 设置当前栏目及当前卡片，配置hash跳转
    const refreshMenu = ()=>{
        let [catePath,newsId] = parseHashString();
        console.log("refreshMenu, catePath:", catePath, " newsId:",newsId);
        if (catePath) {
            // 查找并设置对应栏目
            let sliceIndex = categoryList.findIndex((category)=>{
                return category['path'] === catePath;
            })
            if (sliceIndex > 0) {
                let categoryListUpdate = [...categoryList.slice(sliceIndex), ...categoryList.slice(0, sliceIndex)]
                for(let i=0;i<categoryListUpdate.length;i++){
                    categoryList[i] = categoryListUpdate[i];
                }
            }
            // console.log("refreshMenu, categoryList:", categoryList);
        }
        if (newsId) {
            // 设置默认卡片
            lastPushArticleId.value = newsId
        }
    }

    // 获取栏目
    const fetchMenu = async () =>{

        let res = []
        res = await fetchMenuData()
        console.log("fetchMenu:", res.data)
        for(let i=0;i<3;i++){
            if( res.data==undefined ){
                res = await fetchMenuData()
            }
        }
        if(res.data&&res.data.length>1){
            res.data.forEach((menuItem,menuIndex)=> {
                let cateOne = {
                    id: ""+menuItem['id'],
                    text: ""+menuItem['name'],
                    path: ""+menuItem['path'],
                    page: 1,
                    articleList: [],
                    articleIndex: 0,
                }
                categoryList[menuIndex] = cateOne
            })
        }
        // console.log("fetchMenu categoryList:", categoryList)
    }

    // 获取数据 传入当前栏目id及页码
    const fetchData = async (categoryId, page, loadCache=false) => {

        let loadFirstCardId = lastPushArticleId.value||0
        if( Object.keys(firstFlycard).length>0 || loadCache ){
            console.log("fetchData, load more...", categoryId, page);
            loadFirstCardId = 0
        }else{
            console.log("fetchData...", categoryId, page, " load first:", loadFirstCardId)
        }
        let res = []
        // res = await fetchData1(categoryId, page)
        // res = await fetchData1(310764, 1)
        let news_req = await fetchNewsData(categoryId, page, loadFirstCardId)
        res = news_req&&news_req.data ? news_req.data : []
        console.log("fetchData,", categoryId, page, " res:", res)

        res.forEach(article => {
            article['imgurl'] = article['img1']??article['imgurl']
            if (article['imgurl']) {
                article['touching'] = false;
                // let content = article['content'].replace(/poster=\"|src=\"/g, 'data-src="')
            }
        })
        categoryList.forEach(category => {
            if (categoryId === category['id']) {
                category['articleList'] = [...category['articleList'], ...res]
            }
        })
        // console.log("fetchData, curArticleList:", curArticleList.value)
        
        if (Object.keys(firstFlycard).length==0 && categoryList[0]['articleList'].length>0 && categoryList[0]['articleList'].length <= pageSize.value) {
            // console.log('here');
            // nid 代替 articleId
            // let tmp = categoryList[0]['articleList'].filter(flycard => flycard['nid'] === firstFlycard['nid'])

            firstFlycard = reactive(categoryList[0]['articleList'][0])
            
            // categoryList[0]['articleList'].forEach((flycard, index) => {
            //     if (flycard['nid'] === firstFlycard['nid']) {
            //         console.log(index, flycard);
            //         if (index <= 0) {
            //             categoryList[0]['articleList'] = [...categoryList[0]['articleList'].slice(index + 1)]
            //         } else {
            //             categoryList[0]['articleList'] = [...categoryList[0]['articleList'].slice(0, index - 1), ...categoryList[0]['articleList'].slice(index + 1)]
            //         }
            //     }
            // })

            // categoryList[0]['articleList'].unshift(firstFlycard)

            console.log("fetchData, firstCard:", firstFlycard["nid"], firstFlycard["title"])
            playerInit(firstFlycard) // 初始化播放器
        }
        console.log("fetchData,", categoryId, page," loadFirstCardId:", loadFirstCardId, " loadCache:", loadCache)
        if( !!loadFirstCardId || !loadCache ){
            getLikeCurCount()
            // playerInit(curArticle.value) // 初始化播放器
            setShare() // 初始化分享
            setHash() // 初始化地址参数
        }
    }

    // 获取卡片详情 传入文章id
    const fetchFlycardDetail = async (articleId) => {
        let res = await fetchDetail(articleId)
        return res

    }

    const setPageCookie = () => {
        let seconds = 600;
        let expires = new Date(new Date() * 1 + seconds * 1000)
        Cookies.set('cardpage', curPage.value, {
            expires
        })
    }

    const likeCount = ref(0)

    const getLikeCurCount = async () => {
        likeCount.value = 0
        if(!!curArticle.value){
            likeCount.value = curArticle.value['hits'] || 0
        }
    }

    const changeCurCategory = (change) => {
        if (change === 1) {
            // +
            curCategoryIndex.value++;
            if (curCategoryIndex.value >= categoryList.length) curCategoryIndex.value = 0;
        } else if (change === 0) {
            // -
            curCategoryIndex.value--;
            if (curCategoryIndex.value < 0) curCategoryIndex.value = categoryList.length - 1;
        }
    };

    const changeCurArticle = (change) => {
        if (change === 1) {
            // +
            curArticleIndex.value++;
            if (curArticleIndex.value >= curArticleList.value.length) curArticleIndex.value = curArticleList.value.length - 1;
        } else if (change === 0) {
            // -
            curArticleIndex.value--;
            if (curArticleIndex.value < 0) curArticleIndex.value = 0;
        }
    };

    const openDetail = (e, detail) => {

        if (!e.target.classList.contains('card-item-poster') && !e.target.classList.contains('card-video-pause') && !e.target.classList.contains('card-item-search-img')) {

            if (isWeappEnv()) {
                let path = `/pages/index/index?tp=${detail.cid}&id=${detail.nid}`
                wx.miniProgram.navigateTo({
                    url: path
                });
            } else {
                window.location.href = detail.url + '?from=card'
            }
        }
    };

    const isWeappEnv = () => {
        return window.__wxjs_environment === 'miniprogram' ? true : false
    }

    const debounce = (func, wait) => {
        let timeout = null;
        let context = this;
        let args = arguments;

        return function () {
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(function () {
                func.apply(context, args)
            }, wait);
        }
    }

    const setShare = debounce(function() {
            let href = window.location.href;
            console.log("setShare, href:",href);

            let cur = curArticle.value
            if (!!cur) {
                console.log("setShare, hash:", '#/' + curCategoryPath.value + '/' + cur["nid"] );
                //todo 更换分享
                wxshare.ShareLocal(
                    cur["title"] + '_洛阳网快卡阅读',
                    "洛阳网\n国家一类新闻网站\n河南省重点新闻网站",
                    cur["img1"] || cur["imgurl"],//'',
                    `https://card.lyd.com.cn/#/${curCategoryPath.value}/${cur["nid"]}`,
                    ""
                );
            }
        }, 300)
    ;


    const lastPushArticleId = ref('')
    const setHash = () => {
        //todo 配置hash地址或query方式
        setTimeout(() => {
            let cur = curArticle.value
            if (!!cur) {
                $router.replace({
                    path: '/',
                    hash: '#/' + curCategoryPath.value + '/' + cur["nid"],
                    // query: {
                    //     category: curCategoryId.value,
                    //     id: cur["nid"]
                    // }
                })
                // document.title = cur['title'] + '_洛阳网快卡阅读'
                let pushUrl = '/' + curCategoryPath.value + '/' + cur["nid"] + '?from=push'
                // let pushUrl = '/?category=' + curCategoryId.value + '&id=' + cur["nid"] + '&from=push'
                console.log("setHash, pushUrl:",pushUrl);

                //百度统计设置
                window._hmt && window._hmt.push(['_trackPageview', pushUrl])

                if (lastPushArticleId.value === cur["nid"]) return false

                lastPushArticleId.value = cur["nid"]
            }

        }, 100);


    }

    const preloadData = async () =>{
        //预加载上一个栏目
        let prevCateIndex = curCategoryIndex.value-1
        if( prevCateIndex < 0 ){
            prevCateIndex = categoryList.length - 1
        }
        let prevCategoryId = categoryList[prevCateIndex]["id"]
        console.log("globalInit, prevCateIndex:", prevCateIndex, " prevCategoryId:", prevCategoryId)
        prevCategoryId && fetchData(prevCategoryId, 1, true)
        //预加载下一个栏目
        let nextCateIndex = curCategoryIndex.value+1
        if( nextCateIndex >= categoryList.length ){
            nextCateIndex = 0
        }
        let nextCategoryId = categoryList[nextCateIndex]["id"]
        console.log("globalInit, nextCateIndex:", nextCateIndex, " nextCategoryId:", nextCategoryId)
        nextCategoryId && fetchData(nextCategoryId, 1, true)
    }

    const globalInit = async () => {

        console.log("globalInit...");

        window.addEventListener('resize', function () {
            winWidth.value = document.body.offsetWidth
            winHeight.value = document.body.offsetHeight
        })

        // refreshMenu();

        let page = Cookies.get('cardpage') || 1
        if (!page) {
            page = curPage.value
            setPageCookie()
        }

        //获取最新栏目数据
        await fetchMenu()

        //将默认hash中的栏目移动至当前curCategoryIndex=0位置
        refreshMenu()

        //带首张卡片参数加载列表数据
        console.log("globalInit, curCategoryId:", curCategoryId.value, " curPage:",curPage.value);
        await fetchData(curCategoryId.value, curPage.value)

        // 预加载
        if( categoryList[0]['articleList'].length>0 ){
            preloadData()
        }

    }
    // globalInit()





    watch([curCategoryIndex, curPage, curArticleIndex], async ([newCategoryIndex, newPage, newArticleIndex], [oldCategoryIndex, oldPage, oldArticleIndex]) => {

        if (oldCategoryIndex != newCategoryIndex) {
            // 栏目改变
            
            console.log('watch, change Category:',oldCategoryIndex," => ",newCategoryIndex, curCategoryIndex.value," length:", curCategory.value['articleList'].length);

            //切换栏目时停止播放
            playerStatus.value = 0;

            if (curCategory.value['articleList'].length === 0) {
                // 新栏目未加载过数据
                await fetchData(curCategoryId.value, curPage.value)
                // 预加载
                preloadData()
            }


        } else {
            // 同栏目下

            if (newArticleIndex === Math.max(1, curArticleList.value.length-4)) {
                console.log('watch, load more:',newArticleIndex);
                //快到底部 todo 数据是否加载判断
                if ( curArticle.value['hasNextPage'] === 1) {
                    // 有下一页
                    await fetchData(curCategoryId.value, curPage.value + 1)
                }
            }
            if (newArticleIndex > 0 && newArticleIndex % pageSize.value === 0) {
                // 过页
                if (newArticleIndex > oldArticleIndex) {
                    curPage.value++

                } else if (newArticleIndex < oldArticleIndex) {
                    curPage.value--
                }
                localStorage.setItem('cardpage', curPage.value)
            }
        }

        console.log('watch, change here');

        setPageCookie()
        // fetchFlycardDetail
        getLikeCurCount()
        // let flyCardJson = await fetchFlycardDetail(curArticle.value['articleId'])
        // playerInit(flyCardJson)

        playerInit(curArticle.value)

        // debounce(setShare, 1000)
        //todo 修改为只更新分享信息，不重复获取签名
        setShare()
        setHash()
    })

    return {
        globalInit,
        pageLimit,
        categoryList,
        curCategoryIndex,
        curCategoryId,
        curPage,
        curCategory,
        curArticleList,
        curArticleIndex,
        curArticle,
        changeCurCategory,
        changeCurArticle,
        openDetail,
        setShare,

        playerStatus,
        playerDriving,
        playerPause,
        playerPlay,
        triggerPlayerStatus,
        fetchAndPlay,
        winRatio,
        getLikeCurCount,

        likeCount,

    }



}