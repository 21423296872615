import axios from 'axios'
import {
  getToken,
  setToken
} from '@/utils/auth'



// create an axios instance

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // 
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    
    if (getToken()) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['x-token'] = getToken()
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {

    let token = response['headers']['x-token']
    if (token) setToken(token)

    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (res.code === 2009) {
      return res
      // getCode()
      // window.location.reload()
      // return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug

    // return Promise.reject(error)
    return Promise.resolve(error)
  }
)

export default service